import { type LoaderFunctionArgs } from '@remix-run/node';
import { redirect } from '@remix-run/react';

import { type DtoSingleVenueResponse } from '@lp-lib/api-service-client/public';

import { LoadingSpinner } from '../../src/components/LoadingSpinner';
import {
  APIServiceURL,
  publicFetchAPIService,
} from '../../src/services/public';
import {
  tokenWithRedirect as tokenOrRedirect,
  venueUrlFrom,
} from '../../src/utils/router';

// This doesn't require any of the existing providers, contexts, or
// initialization code to resolve a venue, and serves as a demo of making an
// authenticated request while also handling a login redirect. It also uses the
// new react-router 6.4+ data loader APIs to simplify response/redirect handling
// (e.g. it just throws a response/redirect in case of error, it could do this
// for the purposeful redirect too).

async function resolveMyVenue(token: string | null) {
  return await publicFetchAPIService<DtoSingleVenueResponse>(
    new APIServiceURL('/my/venue'),
    { token: token ?? '' }
  );
}

export const clientLoader = async (action: LoaderFunctionArgs) => {
  const myVenue = await tokenOrRedirect(
    (token) => resolveMyVenue(token),
    action.request.url
  );
  const url = venueUrlFrom(
    action.request.url,
    myVenue.json.venue.id,
    action.params.gamePackId
  );
  return redirect(url.toString());
};

export default function Component() {
  return <LoadingSpinner />;
}
